import React from "react";
import Feature from "../components/Feature";
import { Box, Typography } from "@mui/material";

const LandingPage: React.FC = () => {
  return (
    <>
      {/* <NavBar /> */}
      <Box sx={styles.container}>
        <Box sx={{ margin: "14px 0" }}>
          <Typography sx={styles.homeText}>
            <h2>
              Insurance. <span style={{ color: "#50C878" }}>Augmented.</span>
            </h2>
            <p>Draw insights from your data, effortlessly.</p>
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.features}>
        <Box sx={{ margin: "14px 0" }}>
          <Feature
            icon="faShieldAlt"
            title="Compliance"
            description="Generate fast and compliant reports."
          />
        </Box>
        <Box sx={{ margin: "14px 0" }}>
          <Feature
            icon="faMoneyBillWave"
            title="Financial planning"
            description="Easily analyse your operational and underwriting data to track KPIs"
          />
        </Box>
        <Box sx={{ margin: "14px 0" }}>
          <Feature
            icon="faHandshake"
            title="Integrations"
            description="Automate your process by direct integrtions to your providers and partners."
          />
        </Box>
      </Box>
      <Box sx={styles.container}>
        <Box sx={{ margin: "14px 0" }}>
          <Typography sx={styles.homeText}>
            <p>Coming soon.</p>
          </Typography>
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
};

export default LandingPage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "#121212",
    width: "100%",
    // height: { xs: "100vh", md: "100vh" },
    padding: { xs: "30px 40px", sm: "60px 100px" },
  },
  features: {
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "white",
    padding: { xs: "30px 40px", sm: "60px 400px" },
  },
  homeText: {
    fontSize: {
      xs: "50px",
      md: "74px",
    },
    fontWeight: "600",
    // lineHeight: {
    //   xs: "50px",
    //   md: "74px",
    // },
    color: "#fff",
  },
};
